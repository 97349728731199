.notification-badge {
  position: relative;
  right: -25px;
  top: -54px;
  color: #ffffff;
  background-color: #df0606;
  margin: 0 -.8em;
  border-radius: 20px;
  padding: 4px 8px;
  font-size: 0.80em;
  font-weight: bold;
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif
}

#slide-notif  {
  .infinite-scroll-component {
    overflow: inherit!important;
  }
  #notif-header {
    width: 100%;
  }
}

#slide-notif a:hover {
  background-color: white!important;
}
