.notification-button:hover {
  background-color: black!important;
  opacity: 1;

  i {
    animation: -tilt 0.2s ease-in-out;
    transform: none;
  }
}

@keyframes -tilt {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
