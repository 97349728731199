#ui-input-text:focus, #ui-text-area:focus {
  outline: none !important;
  border-radius: 2px;
  border: thin solid #1976d2 !important;
  box-shadow: 0 0 0 3px #cce5ff !important;
  -moz-box-shadow: 0 0 0 3px #cce5ff !important;
  -webkit-box-shadow: 0 0 0 3px #cce5ff !important;
}

#ui-input-text, #ui-text-area {
  border-radius: 2px;
  border: thin solid #d1d5db !important;
  font-size: 0.9em;
  padding: 7px 7px;
  //resize: vertical;
}

.ui-autocomplete input {
  padding: 0;
  height: 15px!important
}

.ui-autocomplete input:focus {
  padding: 0;
  height: 15px!important;
  border-bottom-color: transparent!important;
  box-shadow: none!important;
}


