//@import "@bangle.dev/core/style.css";
//@import "@bangle.dev/tooltip/style.css";
//@import "@bangle.dev/react-menu/style.css";
//@import url("https://unpkg.com/@bangle.dev/core@0.31.6/style.css");
//@import url("https://unpkg.com/@bangle.dev/react-menu@0.31.6/style.css");
//@import url("https://unpkg.com/@bangle.dev/tooltip@0.31.6/style.css");

.bangle-editor {
  border-radius: 2px;
  border: thin solid #d1d5db !important;
  font-size: 0.9em;
  padding: 7px 7px;
}

.bangle-editor:focus {
  outline: none !important;
  border-radius: 2px;
  border: thin solid #1976d2 !important;
  box-shadow: 0 0 0 3px #cce5ff !important;
  -moz-box-shadow: 0 0 0 3px #cce5ff !important;
  -webkit-box-shadow: 0 0 0 3px #cce5ff !important;
}

.bangle-editor > p {
  margin: 0 !important;
  font-size: 1.2em;
}

.bangle-editor > h2 {
  margin: 10px !important;
  font-size: 1.5em;
}

.bangle-editor > h3 {
  margin: 5px !important;
  font-size: 1.4em;
}

.report-input-display > p {
  margin: 0 !important;
}

.report-input-display > h2 {
  margin: 10px !important;
}

.report-input-display > h3 {
  margin: 5px !important;
}