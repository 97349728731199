
  body{
    background: white;
    overflow-x: hidden;
  --orthoptiste-primary: #584EE6;
  --orthoptiste-secondary: #35D4FE;
  --ophtalmologiste-primary: #35D4FE;
  --ophtalmologiste-secondary: #584EE6;
  --secretaire-primary: rgba(253,119,98);
  --secretaire-secondary: rgba(253,201,53,0.8);
  --green-primary: #2AB383 ;
  --green-secondary: rgb(42,179,131) ;
  --externe-primary: var(--orthoptiste-primary);
  --externe-secondary: var(--orthoptiste-secondary);
  --bg-heigth: 25vh;
  --main-color: var(--green-primary);
  --second-color: var(--green-secondary);

  }

  #connect-background{
    position: absolute;
    top: 0;
    left: -100px;
    right: 0;
    bottom: var(--bg-heigth);
    background: #F5F9FB;
    z-index: -1;
    transform: rotate(-10deg);

  }
  .strip{
    position: absolute;
    bottom: -30px;
    height: 30px;
    left: 40px;


  }
  .strip .strip-1{
    background: #fecb68;
    width: 15vw;
    opacity: 0.8;
    height: 30px;
    position: absolute;
    bottom: 25px;
  }
  .strip .strip-2{
    background: #f86a6d;
    width: 25vw;
    height: 30px;
    opacity: 0.8;
    mix-blend-mode: multiply;

  }
  .strip .strip-right-2{
    height: 30px;
    bottom: 25px;
    background: #f86a6d;
    transform: translateX(80vw) translateY(-30px);
    opacity: 0.9;
  }
  .mini .strip-1{
    width: 10vw;
  }
  .mini .strip-2{
    width: 15vw;
  }
  .mini .strip-right-2{
    width: 20vw;
    transform: translateX(90vw);
  }
  .orthoptiste .strip-right-2{
    background: var(--orthoptiste-primary);
  }
  .orthoptiste .strip-1{
    background: var(--orthoptiste-primary);
  }

  .ophtalmologiste .strip-right-2{
    background: var(--ophtalmologiste-primary);
  }
  .ophtalmologiste .strip-1{
    background: var(--ophtalmologiste-primary);
  }
  .ophtalmologiste .strip-2{
    background: var(--ophtalmologiste-secondary);
  }

  .secretaire .strip-2{
    background: var(--secretaire-secondary);
  }
    .secretaire .strip-right-2{
    background: var(--secretaire-primary);
  }
  .secretaire .strip-1{
    background: var(--secretaire-primary);
  }

  .btn,.btn:focus{
    background: var(--main-color);
  }
  .btn i{
    transition: transform 0.2s;

  }
  input:focus{
    border-bottom-color: var(--main-color)!important;
    box-shadow: 0 1px 0 0 var(--main-color)!important;
  }
  label.active{
    color: var(--main-color)!important;
  }
  .btn:hover{
    background: var(--main-color);
    opacity: 0.6;
  }
  .btn:hover i{
    transform: scale(1.3);
  }

.main-color{
  color: var(--main-color)!important;
}
.green{
  background: var(--green-primary)!important;
}

.blue{
  background: var(--ophtalmologiste-primary)!important;
}

.mauve{
  background: var(--orthoptiste-primary)!important;
}
