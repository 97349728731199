.bordered-display {
  border: solid thin lightgrey;
  //transition: all 0.5s ease-in-out;
  //transform-origin: center;
}

//.collapsed {
//  transform: scaleY(0);
//  overflow: hidden;
//}
//
//.expanded {
//  transform: scaleY(1);
//}