// change the color of the input in input-field outlined
// label should be changed as well when input is focused
// except type search

.input-field.outlined {
  input:not([type="search"]):focus {
    border: thin solid #1976d2 !important;
    box-shadow: 0 0 0 3px #cce5ff !important;
    -moz-box-shadow: 0 0 0 3px #cce5ff !important;
    -webkit-box-shadow: 0 0 0 3px #cce5ff !important;
    + label {
      color: #1976d2 !important;
    }
  }
  textarea:not([type="search"]):focus {
    border: thin solid #1976d2 !important;
    box-shadow: 0 0 0 3px #cce5ff !important;
    -moz-box-shadow: 0 0 0 3px #cce5ff !important;
    -webkit-box-shadow: 0 0 0 3px #cce5ff !important;
    outline: none!important;
    + label {
      color: #1976d2 !important;
    }
  }
}

.input-field.outlined.pincode {
  input  {
    margin: auto;
    display: block;
    background: transparent;
    height: 76px;
    //border: none;
    text-align: center;
    font-family: "Avenir", sans-serif !important;
    font-weight: 500;
    font-size: 2em;
    width: 30rem;
    letter-spacing: 25px;
    padding-inline: 10px;
    padding-left: 30px;
    overflow: clip;
  }
}

// small screens
@media only screen and (max-width: 600px) {
  .input-field.outlined.pincode {
    input {
      width: 20rem;
      letter-spacing: 1rem;
    }
  }
}


