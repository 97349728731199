#cv_modal{
  height: 95% !important;
  max-height: 100% !important;
  width: 90%;
}

#cv_modal .modal-footer{
  background-color: #ececec;
}

.tt-act-collection {
  a.active {
    background-color: #1976d2 !important;
    color: white!important;
  }
  a {
    background-color: #fff !important;
    color: black !important;
  }
}
