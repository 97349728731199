.bridge-statut::before{
    content: " ";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.bridge-Disponible::before{
  background: #2ecc71;
}
.bridge-Indisponible::before{
  background: #e74c3c;
}
.bridge-chargement::before{
  background: #95a5a6;
}
