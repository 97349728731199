@charset "UTF-8";

// Color
@import "components/color-variables";
@import "components/color-classes";

// Variables;
@import "components/variables";


$primary-color: #1976d2;
$secondary-color: #00A7E1;
$lastcolor: #A5D8FF;
$navbar-bg-color-non-focus: #fff;
$navbar-bg-color-hover: #eee;
$navbar-font-color: black;
$progress-bar-color: #1976d2;
$progress-bar-track-color: #9cc5ec;

$switch-thumb-checked-color: #3185d8;
$switch-track-checked-bg: #a6cbed;
$switch-reaction-checked-focus-color: rgba(var(#1976d2), 0.15);
$switch-reaction-checked-hover-color: rgba(var(#1976d2), 0.06);


// Reset
@import "components/normalize";

// components
@import "components/global";
@import "components/collection";
@import "components/badges";
@import "components/icons-material-design";
@import "components/grid";
@import "components/navbar";
@import "components/typography";
@import "components/transitions";
@import "components/cards";
@import "components/toast";
@import "components/tabs";
@import "components/tooltip";
@import "components/buttons";
@import "components/dropdown";
@import "components/modal";
@import "components/collapsible";
@import "components/chips";
@import "components/materialbox";
@import "components/forms/forms";
@import "components/table_of_contents";
@import "components/sidenav";
@import "components/preloader";
@import "components/slider";
@import "components/carousel";
@import "components/tapTarget";
@import "components/pulse";
@import "components/datepicker";
@import "components/timepicker";
@import "components/_theme_variables";

// temeoo scss
@import "temeoo/application";
@import "temeoo/accespatients";
@import "temeoo/appointements";
@import "temeoo/pinlogin";
@import "temeoo/quill";
@import "temeoo/toasts.min";
@import "temeoo/bridge";
@import "temeoo/strip-design";
@import "temeoo/analystes/images";
@import "temeoo/carteVitale/carteVitale";
@import "temeoo/orthoptistes/notifications";
@import "temeoo/orthoptistes/reports";
@import "temeoo/orthoptistes/appointment_sidebar";
@import "temeoo/orthoptistes/statistics";
@import "temeoo/ui_kit/bordered_display";
@import "temeoo/ui_kit/input_text";
@import "temeoo/ui_kit/loader";
@import "temeoo/ui_kit/markdown_editor";
@import "temeoo/ui_kit/pincode";
@import "temeoo/ui_kit/utils";
@import "temeoo/ui_kit/user_dropdown";
@import "temeoo/ui_kit/notification-button";
@import "temeoo/ui_kit/pagination";
@import "temeoo/ui_kit/more-button";
@import "temeoo/ui_kit/scrollbar";
@import "temeoo/ui_kit/smart-text-area";
@import "temeoo/ui_kit/temeoo-table";
@import "temeoo/ui_kit/temeoo-nav";
@import "temeoo/ui_kit/materialize/input-field";
@import "temeoo/users/user-dropdown";
@import "temeoo/bugfixes/navbar";
@import "temeoo/bugfixes/buttons";
@import "temeoo/internal_users/console_router";
@import "temeoo/reports/reports";
@import "temeoo/messenger/messenger";
@import "temeoo/geocoder";

// others
@import 'toastify';



