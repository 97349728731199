.more-button {
  background-color: transparent;
  border-radius: 50%;
  padding-inline: 7px;
}

.more-button:hover {
  background-color: #eaeaea;
}

.more-button-dropdown {
  background-color: white!important;
  border-radius: 20px;
  box-shadow: none;
  border: 2px solid #eaeaea;
  a {
    background-color: white!important;
  }
  a:hover {
    background-color: #eaeaea!important;
  }
}