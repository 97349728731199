.hover-container:hover .hover-button {
  display: inline-block;
}

.hover-button {
  display: none;
}

i.flipped {
  transform: rotate(180deg)!important;
}