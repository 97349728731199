
/* default, enabled field */
.pinlogin .pinlogin-field {
  box-sizing: border-box!important;

  display: inline-block!important;

  width: 45px!important;
  height: 45px!important;

  padding: 5px!important;

  margin: 0 5px!important;

  border: 1px solid #ddd!important;

  -webkit-border-radius: 45px!important;
  -moz-border-radius: 45px!important;
  border-radius: 45px!important;

  text-align: center!important;

  font-family: Arial!important;
  font-size: 13pt!important;
  font-weight: bold!important;
  color: rgba(0,174,255,1)!important;

  outline:none!important;

  -webkit-box-shadow: inset 1px 1px 2px 0px rgba(0,0,0,.1)!important;
  -moz-box-shadow: inset 1px 1px 2px 0px rgba(0,0,0,.1)!important;
  box-shadow: inset 1px 1px 2px 0px rgba(0,0,0,.1)!important;

  background: #fff!important;

  transition: all .5s!important;

}

.pinlogin .pinlogin-field:first-of-type {
    margin-left: 0!important;
}

.pinlogin .pinlogin-field:last-of-type {
    margin-right: 0!important;
}

/* field with focus */
.pinlogin .pinlogin-field:focus {
  border-color: rgba(0,174,255,1)!important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,174,255,1)!important;
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,174,255,1)!important;
  box-shadow: 0px 0px 5px 0px rgba(0,174,255,1)!important;
}

/* field that's readonly */
.pinlogin .pinlogin-field:read-only
{
  border-color:#ddd!important;
  color:#aaa!important;
  background:#f5f5f5!important;
  cursor:default!important;

  -webkit-box-shadow: none!important;
  -moz-box-shadow: none!important;
  box-shadow: none!important;
}

/* field that's invalid */
.pinlogin .pinlogin-field.invalid
{
  border-color:red!important;
  color:red!important;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(255,0,0,1)!important;
  -moz-box-shadow: 0px 0px 5px 0px rgba(255,0,0,1)!important;
  box-shadow: 0px 0px 5px 0px rgba(255,0,0,1)!important;
}
