.slider {
  position: relative;
  height: 400px;
  width: 100%;

  // Fullscreen slider
  &.fullscreen {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ul.slides {
      padding-left: 0;
      list-style-type: none;
      height: 100%;
    }

    ul.indicators {
      padding-left: 0;
      list-style-type: none;
      z-index: 2;
      bottom: 30px;

      .indicator-item {
        background-color: $slider-fullscreen-indicator-color;

        &.active {
          background-color: $slider-fullscreen-indicator-color-active;
        }
      }

    }
  }

  .slides {
    background-color: $slider-bg-color;
    margin: 0;
    height: 400px;
    padding-left: 0;
    list-style-type: none;

    li {
      padding-left: 0;
      list-style-type: none; 
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: inherit;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }

      .caption {
        color: #fff;
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        opacity: 0;

        p {
          color: rgba(255, 255, 255, 0.75);
        }
      }

      &.active {
        z-index: 2;
      }
    }
  }

  .indicators {
    padding-left: 0;
    list-style-type: none;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;

    .indicator-item {
      display: inline-block;
      position: relative;
      height: 16px;
      width: 16px;
      margin: 0 12px;
    }

    .indicator-item-btn {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      background-color: $slider-indicator-color;

      transition: background-color .3s;
      border-radius: 50%;
      border-width: 0;
      
      &.active {
        background-color: $slider-indicator-color-active;
      }

      width: 100%;
      height: 100%;
    }
  }
}