.pincode-input-container {
  display: flex;
  gap: 5px;

  .pincode-input-text {
    padding: 0 !important;
    margin: 0 2px;
    text-align: center;
    //border: 1px solid;
    background: transparent;
    width: 45px!important;
    height: 45px!important;
    border-radius: 50%;
    border: 1px solid #ddd !important;
    outline: none !important;
    color: rgb(0, 174, 255) !important;
    text-transform: none;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  }

  .pincode-input-text:focus {
    //box-shadow: none !important;
    border-color: #9cdeff !important;
    -webkit-box-shadow: 0 0 5px 0 #9cdeff !important;
    -moz-box-shadow: 0 0 5px 0 #9cdeff !important;
    box-shadow: 0 0 5px 0 #9cdeff !important;
  }
}