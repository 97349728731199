.messenger-input:focus {
  box-shadow: none !important;
  border: none !important;
  margin: 0 !important;
}

.messenger-textarea {
  border-radius: 20px;
  line-height: 20px;
  resize: none;
  box-shadow: none !important;
  border: none !important;
  margin: 0 !important;
  outline: none;
}

.messenger-textarea:focus {
  box-shadow: none !important;
  border: none !important;
  margin: 0 !important;
  border-bottom-color: transparent;
}

.messenger-navlink > a {
  margin-inline: 15px !important;
}

.messenger-navlink > a.active {
  background-color: #d7d6d6 !important;
}
