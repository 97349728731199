// SCROLLBAR

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box; // <== make the border work
}

::-webkit-scrollbar-thumb:hover {
  border: 0;
  background: #595959;
}

::-webkit-scrollbar-thumb:active {
  border: 0;
  background: #1976d2;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.hoverable-scroll-box {
  overflow-y: hidden;
  scrollbar-gutter: stable;
}

.hoverable-scroll-box:hover {
  overflow-y: scroll;
}