@use "sass:math";

// Container
.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
/*
.col .row {
  margin-left: (-1 * $gutter-width * 0.5);
  margin-right: (-1 * $gutter-width * 0.5);
}
*/

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  // No documented
  /*
  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
  */
}

// Mixins to eliminate code repetition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  //--grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr); //repeat(12, calc(8.33% - var(--grid-gap) + (var(--grid-gap) / 12)));
  //gap: var(--grid-gap);

  //--- S

  .s1  { grid-column: auto / span 1; }
  .s2  { grid-column: auto / span 2; }
  .s3  { grid-column: auto / span 3; }
  .s4  { grid-column: auto / span 4; }
  .s5  { grid-column: auto / span 5; }
  .s6  { grid-column: auto / span 6; }
  .s7  { grid-column: auto / span 7; }
  .s8  { grid-column: auto / span 8; }
  .s9  { grid-column: auto / span 9; }
  .s10 { grid-column: auto / span 10; }
  .s11 { grid-column: auto / span 11; }
  .s12 { grid-column: auto / span 12; }

  .offset-s1  { grid-column-start:  2; }
  .offset-s2  { grid-column-start:  3; }
  .offset-s3  { grid-column-start:  4; }
  .offset-s4  { grid-column-start:  5; }
  .offset-s5  { grid-column-start:  6; }
  .offset-s6  { grid-column-start:  7; }
  .offset-s7  { grid-column-start:  8; }
  .offset-s8  { grid-column-start:  9; }
  .offset-s9  { grid-column-start: 10; }
  .offset-s10 { grid-column-start: 11; }
  .offset-s11 { grid-column-start: 12; }

  //--- M
  @media #{$medium-and-up} {
    .m1  { grid-column: auto / span 1; }
    .m2  { grid-column: auto / span 2; }
    .m3  { grid-column: auto / span 3; }
    .m4  { grid-column: auto / span 4; }
    .m5  { grid-column: auto / span 5; }
    .m6  { grid-column: auto / span 6; }
    .m7  { grid-column: auto / span 7; }
    .m8  { grid-column: auto / span 8; }
    .m9  { grid-column: auto / span 9; }
    .m10 { grid-column: auto / span 10; }
    .m11 { grid-column: auto / span 11; }
    .m12 { grid-column: auto / span 12; }
  
    .offset-m1  { grid-column-start:  2; }
    .offset-m2  { grid-column-start:  3; }
    .offset-m3  { grid-column-start:  4; }
    .offset-m4  { grid-column-start:  5; }
    .offset-m5  { grid-column-start:  6; }
    .offset-m6  { grid-column-start:  7; }
    .offset-m7  { grid-column-start:  8; }
    .offset-m8  { grid-column-start:  9; }
    .offset-m9  { grid-column-start: 10; }
    .offset-m10 { grid-column-start: 11; }
    .offset-m11 { grid-column-start: 12; }
  }

  //--- L
  @media #{$large-and-up} {
    .l1  { grid-column: auto / span 1; }
    .l2  { grid-column: auto / span 2; }
    .l3  { grid-column: auto / span 3; }
    .l4  { grid-column: auto / span 4; }
    .l5  { grid-column: auto / span 5; }
    .l6  { grid-column: auto / span 6; }
    .l7  { grid-column: auto / span 7; }
    .l8  { grid-column: auto / span 8; }
    .l9  { grid-column: auto / span 9; }
    .l10 { grid-column: auto / span 10; }
    .l11 { grid-column: auto / span 11; }
    .l12 { grid-column: auto / span 12; }
  
    .offset-l1  { grid-column-start:  2; }
    .offset-l2  { grid-column-start:  3; }
    .offset-l3  { grid-column-start:  4; }
    .offset-l4  { grid-column-start:  5; }
    .offset-l5  { grid-column-start:  6; }
    .offset-l6  { grid-column-start:  7; }
    .offset-l7  { grid-column-start:  8; }
    .offset-l8  { grid-column-start:  9; }
    .offset-l9  { grid-column-start: 10; }
    .offset-l10 { grid-column-start: 11; }
    .offset-l11 { grid-column-start: 12; }
  }

  //--- XL
  @media #{$extra-large-and-up} {
    .xl1  { grid-column: auto / span 1; }
    .xl2  { grid-column: auto / span 2; }
    .xl3  { grid-column: auto / span 3; }
    .xl4  { grid-column: auto / span 4; }
    .xl5  { grid-column: auto / span 5; }
    .xl6  { grid-column: auto / span 6; }
    .xl7  { grid-column: auto / span 7; }
    .xl8  { grid-column: auto / span 8; }
    .xl9  { grid-column: auto / span 9; }
    .xl10 { grid-column: auto / span 10; }
    .xl11 { grid-column: auto / span 11; }
    .xl12 { grid-column: auto / span 12; }
  
    .offset-xl1  { grid-column-start:  2; }
    .offset-xl2  { grid-column-start:  3; }
    .offset-xl3  { grid-column-start:  4; }
    .offset-xl4  { grid-column-start:  5; }
    .offset-xl5  { grid-column-start:  6; }
    .offset-xl6  { grid-column-start:  7; }
    .offset-xl7  { grid-column-start:  8; }
    .offset-xl8  { grid-column-start:  9; }
    .offset-xl9  { grid-column-start: 10; }
    .offset-xl10 { grid-column-start: 11; }
    .offset-xl11 { grid-column-start: 12; }
  }

}
