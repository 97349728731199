.smart-text-area {
  border: thin solid #c4c4c4!important;

  margin: 0!important;
  padding: 10px!important;
}

.smart-text-area:focus {
  box-shadow: 0 0 0 3px #cce5ff!important;
  border: thin solid #1976d2!important;
  margin: 0!important;
}

.smart-text-area:hover {
  outline: none;
  border: thin solid black!important;
}