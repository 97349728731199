.sidenav {
  background-color: white !important;
  padding: 20px 0;
  margin-top: 92px;
  overflow-y: scroll;
  height: calc(100% - 92px);
}

.sidenav li {
  background-color: transparent !important;
}

.sidenav a {
  //background-color: #eeeeee !important;
  margin: 0 20px 0 40px !important;
  padding: 0 10px 0 10px !important;
  border-radius: 40px;
}

.sidenav a:hover {
  background-color: #eeeeee !important;
  //color: red !important;
}

a.collapsible-header {
  margin: 0 20px 0 20px !important;
}

.sidenav a.nav-action-btn {
  margin-inline: 0!important;
  background-color: #1976d2!important;
}
.sidenav a.nav-action-btn:hover {
  margin-inline: 0!important;
  background-color: #1976d2!important;
  opacity: 0.7;
}

.sidenav a.nav-back-btn {
  margin-inline: 0!important;
  background-color: #d32f2f!important;
}
.sidenav a.nav-back-btn:hover {
  margin-inline: 0!important;
  background-color: #d32f2f!important;
  opacity: 0.7;
}
