.loader {
  overflow: hidden;
  position: relative;
  height: 85px;
}

.reflect {
  width: 50px;
  height: 300px;
  background: #FFFFFF;
  rotate: 45deg;
  position: absolute;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-200%) ;
  }
  50% {
    transform:  translateX(600%);
  }
  100% {
    transform:  translateX(600%);
  }
}
