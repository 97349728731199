// Place all the styles related to the analystes::images controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.center_card{
  display: flex;
  justify-content: center;
}
.card{
  padding: 20px;
}

.btn_display{
  display: flex;
  justify-content: space-evenly;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}