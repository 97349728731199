.temeoo-table {
  font-size: 0.7rem;
  border: thin solid #dddddd;
  th {
    font-size: 0.8rem;
    padding-inline: 1em;
    padding-block: 5px;
  }
  td {
    padding-inline: 1em;
    padding-block: 5px;
  }
  a {
    font-size: 0.7rem;
    padding-inline: 10px;
    i {font-size: 1rem;}
  }

  p {margin: 0;}

  tr.highlighted:hover {background-color: #f5f5f5;}
}