#geocoder {
  input {
    font-size: 1.2rem;
    height: 100%;
    padding-inline: 20px 40px;
    margin-block: 0px;
  }
  .mapboxgl-ctrl-geocoder--icon-search {
    display: none;
  }
  .mapboxgl-ctrl-geocoder--icon-loading {
    display: none;
  }
  .mapboxgl-ctrl-geocoder--icon-close {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .mapboxgl-ctrl-geocoder--pin-right {
    height: 0;
  }
}