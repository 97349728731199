/* Select Field
   ========================================================================== */

select.browser-default {
  opacity: 1;
  color: $select-input-color;
}

select {
  opacity: 0;
  background-color: $select-background;
  width: 100%;
  padding: $select-padding;
  border: $select-border;
  border-radius: $select-radius;
  height: $input-height;
}

// .select-label {
//   position: absolute;
// }


.select-wrapper {
  /*
  &.valid .helper-text[data-success],
  &.invalid ~ .helper-text[data-error] {
    @extend %hidden-text;
  }

  &.valid {
    & > input.select-dropdown {
      @extend %valid-input-style;
    }
    & ~ .helper-text:after {
      //@extend %custom-success-message;
    }
  }

  &.invalid {
    & > input.select-dropdown,
    & > input.select-dropdown:focus {
      @extend %invalid-input-style;
    }
    & ~ .helper-text:after {
      //@extend %custom-error-message;
    }
  }

  &.valid + label,
  &.invalid + label {
    width: 100%;
    pointer-events: none;
  }
  & + label:after {
    //@extend %input-after-style;
  }
  */



  position: relative;

  /*
  input.select-dropdown {    
    &:focus {
      border-bottom: 1px solid $input-focus-color;
    }
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: $input-border;
    outline: none;
    height: $input-height;
    line-height: $input-height;
    width: 100%;
    font-size: $input-font-size;
    margin: $input-margin;
    padding: 0;
    display: block;
    user-select:none;
    z-index: 1;
    color: $select-input-color;
  }
  */

  .caret {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: 0;
    fill: $select-input-color;
  }

  /*
  & + label {
    position: absolute;
    top: -26px;
    font-size: $label-font-size;
  }
  */

  // Hide select with overflow hidden instead of using display none
  // (this prevents form validation errors with hidden form elements)
  .hide-select {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -1;
  }
}

// Disabled styles
select:disabled {
  color: $input-disabled-color;
}

.select-wrapper.disabled {
  + label {
    color: $input-disabled-color;
  }
  .caret {
    fill: $input-disabled-color;
  }
}

.select-wrapper input.select-dropdown:disabled {
  color: $input-disabled-color;
  cursor: default;
  user-select: none;
}

.select-wrapper i {
  color: $select-disabled-color;
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: $select-disabled-color;
  background-color: transparent;
}

body.keyboard-focused {
  .select-dropdown.dropdown-content li:focus {
    background-color: $select-option-focus;
  }
}

.select-dropdown.dropdown-content {
  li {
    &:hover:not(.disabled) {
      background-color: $select-option-hover;
    }

    &.selected:not(.disabled) {
      background-color: $select-option-selected;
    }
  }
}

/*
// Prefix Icons
.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}
.prefix ~ label { margin-left: 3rem; }
// Suffix Icons
.suffix ~ .select-wrapper {
  margin-right: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}
.suffix ~ label { margin-right: 3rem; }
*/

// Icons
.select-dropdown li {
  img {
    height: $dropdown-item-height - 10;
    width: $dropdown-item-height - 10;
    margin: 5px 15px;
    float: right;
  }
}

// Optgroup styles
.select-dropdown li.optgroup {
  border-top: 1px solid $dropdown-hover-bg-color;

  &.selected > span {
    color: $font-color-main;
  }

  & > span {
    color: $font-color-medium;
  }

  & ~ li.optgroup-option {
    padding-left: 1rem;
  }
}
