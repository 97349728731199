
.indicator{
  display: none;
}

.ortho_stat_tabs {
  margin-top: 1px
}
.ortho_stat_tabs li a {
  background-color: transparent;
  color: #6c6c6c !important;
}

.ortho_stat_tabs li a.active {
  background-color: lightgrey!important;
}
.ortho_stat_tabs li a:hover {
  background-color: #efefef;
}