.temeoo-nav {
  padding: 0;
  width: 300px;
  overflow-y: auto;

  li {
    padding-inline: 20px;
    margin-block: 5px;
  }

  a {
    width: 100%;
    border-radius: 10px;
  }

  .collapsible {
    box-shadow: none;
    border: none;
    margin: 0;

    .collapsible-header {
      border: none;
      padding-block: 5px
    }

    .collapsible-body {
      border: none;
    }

    li {
      padding-inline: 0;
      margin-block: 5px;
    }
  }

}

.temeoo-nav.collapsed {
  width: 50px;
  padding: 0;

  li {
    padding-inline: 0;
    margin-block: 0;
  }

  a {
    width: 100%;
    border-radius: 0;
  }
}

.temeoo-collapsible-nav {
  padding: 0;
  width: 100%;

  li {
    padding-inline: 0;
    margin-block: 5px;
  }

  a {
    width: 100%;
    border-radius: 10px;
  }
}

